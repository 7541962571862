<template>
    <div v-if="dataSource && dataSourceType === 'OutgoingDocument'"
        :key="document.id"
        class="p-relative"
    >

        <Toolbar 
            :menu="dataSource.Data.Menu" 
            v-on:toolbar-button-click="onToolbarClick"  
        />

        <DocumentPanel :document-info="dataSource.Data.Object" />
        <div class="d-flex after-tabs-part-wrapper">
            <v-tabs
                v-model="tab"
                background-color="transparent"
                slider-size="3"
                class="tab-selector-panel"
                mobile-breakpoint="0"
            >
                <v-tabs-slider color="#E43958"></v-tabs-slider>

                <v-tab
                    v-for="item in pages"
                    :key="document.id + item.FormId"
                    @click="onTabClicked(item.FormId)"
                >
                    <template v-if="item.Count < 0">
                        {{ item.Name }}
                    </template> 
                    <template v-else>
                        {{ item.Name }}
                        <span class="count" id="attachCounter">{{item.Count}}</span>
                    </template>   
                </v-tab>

            </v-tabs>

            <div
                v-if="isViewMode && document.Version && (document.RegState === 0 || document.RegState === 3 || document.RegState === 5 || document.RegState === 6)"
                class="after-tabs-part"
            >
                <v-doc-version-chip :id="document.id" :text="$t('Версии')" />
            </div>

        </div>

        <v-tabs-items
            v-model="tab"
            class="tab-content-panel"
        >
            <v-tab-item 
                v-for="item in pages"
                :key="document.id + item.FormId"
            >
                <template v-if="item.FormId == document.id">
                    <v-form class="wrapperForm" ref="form" lazy-validation>
                        <v-row dense>
                            <!--Обертка для секции-->
                            <v-col cols="12" sm="12" md="6" class="section-wrapper">

                                <!--Секция Сведения о регистрации-->
                                <v-card flat v-if="isRegistered">
                                    <v-card-text>
                                        <div class="form-box-title">{{$t("Сведения_о_регистрации")}}</div>

                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Журнал_регистрации")}}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <div class="onlyReadData">{{ document.Counter ? document.Counter.Name : '' }}</div>
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Исходящий_номер,_дата")}}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <div class="onlyReadData">{{ concatedRegistrationData  }}</div>
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Регистратор")}}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <div class="onlyReadData">
                                                    <v-workplace-chip :id="document.Card.RegistratorWorkplaceId" :name="document.Card.RegistratorName" />
                                                </div>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                </v-card>

                                <!--Обертка для блока-->
                                <v-card flat>
                                    <!--Обертка для контента блока Реквизиты документа-->
                                    <v-card-text>
                                        <!--заголовок блока-->
                                        <div class="form-box-title">{{$t("Реквизиты_документа")}}</div>

                                        <!--поле Номер проекта (всегда просмотр)-->
                                        <v-row no-gutters v-if="!document.is_new_record">
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Номер_проекта")}}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <div class="onlyReadData">{{ document.Card.DraftNumber }}</div>
                                            </v-col>
                                        </v-row>

                                        <!--поле Инициативный-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Инициативный")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{ document.Card.IsInitiative ? $t("Да") : $t("Нет") }}
                                                </div>
                                                <v-switch
                                                    v-else
                                                    :input-value="document.Card.IsInitiative"
                                                    @change="onIsInitiativeChange($event)"
                                                    inset
                                                    hide-details
                                                    class="cust-switch"
                                                />
                                            </v-col>
                                        </v-row>

                                        <!--поле Ответ на обращение-->
                                        <v-row no-gutters v-show="!isViewMode || !document.Card.IsInitiative">
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Ответ_на_обращение")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{ document.Card.IsEuolAnswer ? $t("Да") : $t("Нет") }}
                                                </div>
                                                <v-switch
                                                    v-else
                                                    :input-value="document.Card.IsEuolAnswer"
                                                    @change="onIsEuolAnswerChange($event)"
                                                    inset
                                                    hide-details                                                    
                                                    class="cust-switch"
                                                />
                                            </v-col>
                                        </v-row>
                                        
                                        <!--поле Номенклатура-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Номенклатура")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{document.Nomenclature ? `${document.Nomenclature.Code} ${document.Nomenclature.Header}` : $t("не_указано")}}
                                                </div>
                                                <!-- <template v-else>
                                                    <div class="icon-click-row-group">
                                                        <div @click="onNomenclatureSelect">
                                                            <v-icon small v-tooltip.left-center="selectNomenclatureTooltip">
                                                                fas fa-edit
                                                            </v-icon>
                                                        </div>
                                                        <v-textarea
                                                            class="oneRowStartTextarea"
                                                            rows="1"
                                                            auto-grow
                                                            row-height="16"
                                                            no-resize
                                                            hide-details
                                                            required
                                                            outlined
                                                            dense
                                                            readonly
                                                            :value="document.Nomenclature ? `${document.Nomenclature.Code} ${document.Nomenclature.Header}` : ''"
                                                            :rules="requiredRule"
                                                        >
                                                        </v-textarea>
                                                    </div>
                                                </template> -->
                                                <v-select
                                                    v-else
                                                    :value="document.NomenclatureId != guidEmpty ? document.NomenclatureId : null"
                                                    @input="onNomenclatureInput($event)"
                                                    :items="nomenclatures"
                                                    :item-text="item => `${item.Code} ${item.Header}`"
                                                    item-value="id"
                                                    return-object
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    :rules="requiredRule"
                                                    append-icon="fas fa-chevron-down"
                                                    :menu-props="{ bottom: true, offsetY: true }"
                                                >
                                                    <template v-slot:prepend-item>
                                                        <v-list-item>
                                                            <v-list-item-content>
                                                                <v-text-field 
                                                                    :placeholder="$t('Поиск')"
                                                                    class="mb-2 autocomplete-search"
                                                                    hide-details
                                                                    dense
                                                                    clearable
                                                                    autofocus
                                                                    v-model="nomenclatureSearch"
                                                                >
                                                                </v-text-field>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-select>
                                            </v-col>
                                        </v-row>

                                        <!--поле Вид документа-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Тип_документа") }}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{document.Card.DocumentType ? document.Card.DocumentType.Value : $t("не_указано")}}
                                                </div>
                                                <v-select
                                                    v-else
                                                    :value="document.Card.DocumentType"
                                                    @input="update({ property: 'Data.Object.Document.Card.DocumentType', value: $event})"
                                                    :items="documentTypeItems"
                                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                                    item-value="id"
                                                    return-object
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    :rules="requiredRule"
                                                    append-icon="fas fa-chevron-down"
                                                    :menu-props="{ bottom: true, offsetY: true }"
                                                >
                                                    <template v-slot:prepend-item>
                                                        <v-list-item>
                                                            <v-list-item-content>
                                                                <v-text-field 
                                                                    :placeholder="$t('Поиск')"
                                                                    class="mb-2 autocomplete-search"
                                                                    hide-details
                                                                    dense
                                                                    clearable
                                                                    autofocus
                                                                    v-model="documentTypeSearch"
                                                                >
                                                                </v-text-field>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-select>
                                            </v-col>
                                        </v-row>

                                        <!--поле Характер вопроса-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Характер_вопроса")}}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{ document.Card.QuestCharacter ? document.Card.QuestCharacter.Value : $t("не_указано") }}
                                                </div>
                                                <v-select
                                                    v-else
                                                    :value="document.Card.QuestCharacter"
                                                    @input="update({ property: 'Data.Object.Document.Card.QuestCharacter', value: $event })"
                                                    :items="questCharacterItems"
                                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                                    item-value="id"
                                                    return-object
                                                    hide-details
                                                    outlined
                                                    dense
                                                    append-icon="fas fa-chevron-down"
                                                    :menu-props="{ bottom: true, offsetY: true }"
                                                >
                                                    <template v-slot:prepend-item>
                                                        <v-list-item>
                                                            <v-list-item-content>
                                                                <v-text-field 
                                                                    :placeholder="$t('Поиск')"
                                                                    class="mb-2 autocomplete-search"
                                                                    hide-details
                                                                    dense
                                                                    clearable
                                                                    autofocus
                                                                    v-model="questCharacterSearch"
                                                                >
                                                                </v-text-field>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-select>
                                            </v-col>
                                        </v-row>

                                        <!--поле Язык документа-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Язык_документа")}}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{document.Card.Languages ? documentLanguages.map((x) => x.Value).join(', ') : $t("не_указано")}}
                                                </div>
                                                <v-autocomplete
                                                    v-else
                                                    v-model="documentLanguages"
                                                    :items="GetReference(1000)"
                                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                                    item-value="id"
                                                    multiple
                                                    return-object
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    :rules="multipleRule"
                                                    append-icon="fas fa-chevron-down"
                                                >
                                                </v-autocomplete>
                                            </v-col>
                                        </v-row>

                                        <!--поле Тип носителя-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Тип_носителя")}}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{document.Card.CarrierType ? document.Card.CarrierType.Value : $t("не_указано")}}
                                                </div>
                                                <v-autocomplete
                                                    v-else
                                                    :value="document.Card.CarrierType"
                                                    @input="update({ property: 'Data.Object.Document.Card.CarrierType', value: $event})"
                                                    :items="GetReference(1024)"
                                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                                    item-value="id"
                                                    return-object
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    :rules="requiredRule"
                                                    append-icon="fas fa-chevron-down"
                                                >
                                                </v-autocomplete>
                                            </v-col>
                                        </v-row>
                                        
                                        <!--поле Количество ЛПЭ-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Количество_листов/приложений") }}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{`${document.Card.ListCount}/${document.Card.AttachCount}`}}
                                                </div>
                                                <div class="more-inputs-in-row" v-else>
                                                    <v-text-field
                                                        :value="document.Card.ListCount"
                                                        @input="update({ property: 'Data.Object.Document.Card.ListCount', value: $event})"
                                                        hide-details
                                                        required
                                                        outlined
                                                        dense
                                                        type="number"
                                                        :rules="integerNotNull"
                                                        class="cust-inputnumber"
                                                        min="0"
                                                    >
                                                    </v-text-field>
                                                    <span>/</span>
                                                    <v-text-field
                                                        :value="document.Card.AttachCount"
                                                        @input="update({ property: 'Data.Object.Document.Card.AttachCount', value: $event})"
                                                        hide-details
                                                        required
                                                        outlined
                                                        dense
                                                        type="number"
                                                        :rules="integerRule.concat(requiredRule)"
                                                        class="cust-inputnumber"
                                                        min="0"
                                                    >
                                                    </v-text-field>
                                                </div>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                </v-card>

                            </v-col>
                            
                            <v-col cols="12" sm="12" md="6" class="section-wrapper">

                                <v-card flat>
                                    <v-card-text>
                                        <div class="form-box-title">{{ $t("Исполнитель/Автор_документа") }}</div>

                                        <!--поле Исполнитель-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Исполнитель")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div class="icon-click-row-group icrg-align-center">
                                                    <div v-if="!isViewMode" @click="onExecuterSelect">
                                                        <v-icon small v-tooltip.left-center="selectExecuterTooltip">
                                                            fas fa-edit
                                                        </v-icon>
                                                    </div>
                                                    <div class="onlyReadData">
                                                        <v-workplace-chip v-if="document.Card.ExecuterName" :id="document.Card.ExecuterWorkplaceId" :name="document.Card.ExecuterName" />
                                                    </div>
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <!--поле Телефон исполнителя-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Телефон_исполнителя")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{document.Card.Phone ? document.Card.Phone : $t("не_указано")}}
                                                </div>
                                                <v-text-field
                                                    v-else
                                                    :value="document.Card.Phone"
                                                    @input="update({ property: 'Data.Object.Document.Card.Phone', value: $event})"
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    :rules="requiredRule"
                                                >
                                                </v-text-field>
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters v-if="document.Card.SignerName">
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Подписант")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div class="onlyReadData">
                                                    <v-workplace-chip :id="document.Card.SignerWorkplaceId" :name="document.Card.SignerName" />
                                                </div>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                </v-card>

                                <v-card flat v-if="isViewMode">
                                    <v-card-text>
                                        <div class="form-box-title">{{ $t("Кем_создано") }}</div>

                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Сотрудник")}}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <div class="onlyReadData">
                                                    <v-employee-chip :id="document.AuthorId" :name="document.AuthorName" />
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Дата_создания")}}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <div class="onlyReadData">{{ formatDate(document.CreateDate) }}</div>
                                            </v-col>
                                        </v-row>
                                    
                                    </v-card-text>
                                </v-card>

                            </v-col>

                            <v-col cols="12" sm="12" md="12" class="section-wrapper">

                                <v-card flat>
                                    <v-card-text>
                                        <div class="form-box-title">{{$t("Краткое_содержание")}}</div>

                                        <v-row class="full-width-row" no-gutters>
                                            <v-col cols="12" sm="12" md="2">
                                                <label class="f-label">{{$t("Краткое_содержание")}}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="10">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{ document.Theme }}
                                                </div>
                                                <v-textarea v-else
                                                    :value="document.Theme"
                                                    @input="update({ property: 'Data.Object.Document.Theme', value: $event})"
                                                    rows="3"
                                                    no-resize
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    :rules="requiredRule"
                                                >
                                                </v-textarea>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                </v-card>
                                
                                <v-card flat v-if="!isBypassing">
                                    <v-card-text>
                                        <div class="form-box-title">{{ $t("Кому_адресовано") }}</div>

                                        <v-row class="full-width-row" no-gutters>
                                            <v-col cols="12" sm="12" md="2">
                                                <label class="f-label">{{ $t("Получатели") }}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="10">
                                                <div class="icon-click-row-group">
                                                    <div v-if="!isViewMode" @click="onRecipientsSelect">
                                                        <v-icon small v-tooltip.left-center="selectRecipientsTooltip">
                                                            fas fa-edit
                                                        </v-icon>
                                                    </div>
                                                    <div class="onlyReadData" v-if="document.Card.Recipients.length == 0">
                                                        <div class="chipAreaNoData"></div>
                                                    </div>
                                                    <div class="onlyReadData more-per-lab-wrap" v-else>
                                                        <template v-for="recipient in document.Card.Recipients">
                                                            <v-enterprise-chip
                                                                v-if="recipient.ContractorType === 0"
                                                                :key="recipient.Id"
                                                                :name="recipient.Name"
                                                                :is-esedo="recipient.IsEsedo"
                                                            />
                                                            <v-workplace-chip
                                                                v-else
                                                                :key="recipient.Id"
                                                                :name="recipient.Name"
                                                                :is-esedo="recipient.IsEsedo"
                                                            />
                                                        </template>
                                                    </div>
                                                </div>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                </v-card>
                                
                                <template v-else>
                                    <v-card flat>
                                        <v-card-text>
                                            <div class="form-box-title">
                                                <span>{{ $t("Кому_адресовано") }}</span>
                                            </div>
                                            <v-row class="full-width-row" no-gutters>
                                                <v-col cols="12" sm="12" md="2">
                                                    <label class="f-label">{{ $t("Получатели") }}</label>
                                                </v-col>
                                                <v-col  cols="12" sm="12" md="10">
                                                    <div class="icon-click-row-group">
                                                        <div class="onlyReadData more-per-lab-wrap">
                                                            <template v-for="(recipient, i) in document.Card.Recipients">
                                                                <v-chip 
                                                                    v-if="!isViewMode"
                                                                    :key="i"
                                                                    small
                                                                    class="person-label"
                                                                    label
                                                                    color="red lighten-4"
                                                                >
                                                                    <div
                                                                        class="if-more-tooltip-chip"
                                                                        v-tooltip="{ content: `<i>${$t('Документ_не_может_быть_отправлен_этому_получателю')}</i>` }"
                                                                    >
                                                                        <div>{{ recipient.Name }}</div>
                                                                    </div>    
                                                                    <div class="pl-actions-wrapper">
                                                                        <span
                                                                            v-tooltip.top="$t('Удалить')"
                                                                            @click.stop="onCustomRecipientDelete(i)"
                                                                        >
                                                                            <i class="fas fa-trash dark-gray-color"></i>
                                                                        </span>
                                                                    </div>
                                                                </v-chip>
                                                                <v-chip v-else
                                                                    :key="i"
                                                                    small
                                                                    class="person-label"
                                                                    color="red lighten-4"
                                                                    v-tooltip="{ content: `<i>${$t('Документ_не_может_быть_отправлен_этому_получателю')}</i>` }"
                                                                    label
                                                                >
                                                                    <div>{{ recipient.Name }}</div>
                                                                </v-chip>
                                                            </template>
                                                            <v-chip
                                                                class="person-label immutable-text"
                                                                small
                                                                label
                                                                outlined
                                                                @click="onCustomRecipientsSelect"
                                                                v-if="!isViewMode"
                                                            >
                                                                <i class="fas fa-plus dark-gray-color"></i>
                                                                <div>{{ $t("Добавить_получателя") }}</div>
                                                            </v-chip>
                                                        </div>
                                                    </div>
                                                </v-col>
                                            </v-row>

                                        </v-card-text>
                                    </v-card>
                                </template>

                            </v-col>

                        </v-row>
                    </v-form>
                </template>
                
                <template v-else-if="item.FormId == 'RouteParams'">
                    <RouteParamsTab
                        :isViewMode="isViewMode"
                        :readonly="dataSource.Data.Object.ProcessInfoReadOnly"
                    />
                </template>

                <template v-else>
                    <component                         
                        :is="$mapComponent(item.FormId)" 
                        :ref="item.FormId" 
                        :id="document.id"
                    />
                </template>

            </v-tab-item>

        </v-tabs-items>
        <CreateCustomRecipientDlg v-if="!isViewMode && isBypassing" ref="CreateCustomRecipientDlgRef" />
    </div>
</template>

<script>
import Toolbar from '@/components/Toolbar.vue';
import DocumentPanel from '@/components/DocumentPanel.vue';
import CreateCustomRecipientDlg from '@/components/dialogs/CreateCustomRecipientDlg';
import { mapActions, mapGetters } from 'vuex';
import i18n from '@/i18n';
import _ from 'lodash';
import sys from '@/services/system';

import RouteParamsTab from '@/components/tabs/static/RouteParams';

export default {
    name: "OutgoingCard",
    components: {
        Toolbar,
        DocumentPanel,
        CreateCustomRecipientDlg,
        RouteParamsTab
    },
    asyncComputed: {
        documentTypeItems: {
            async get () {
                let items = Array.from(await this.$store.dispatch('references/getReference', { id: 1001 }));

                if (this.documentTypeSearch)
                    return items.filter(i => sys.checkSearch(i.Value, this.documentTypeSearch) || i.id == this.document?.Card?.DocumentType?.id);
                else
                    return items;
            },
            default: [],
            watch: ['documentTypeSearch']
        },
        questCharacterItems: {
            async get () {
                let items = Array.from(await this.$store.dispatch('references/getReference', { id: 1003 }));

                if (this.questCharacterSearch)
                    return items.filter(i => sys.checkSearch(i.Value, this.questCharacterSearch) || i.id == this.document?.Card?.QuestCharacter?.id);
                else
                    return items;
            },
            default: [],
            watch: ['questCharacterSearch']
        },
        nomenclatures : {
            async get() {
                if (this.nomenclatureSearch) {                
                    return (await this.$store.dispatch('references/getNomenclatures', { forDepartment: false }))
                        .map(x => ({ id: x[0], Code: x[1], Header: x[2] }))
                        .filter(i => sys.checkSearch(`${i.Code} ${i.Header}`, this.nomenclatureSearch) || i.id == this.nomenclature?.id);
                }
                else
                    return (await this.$store.dispatch('references/getNomenclatures', { forDepartment: false }))
                        .map(x => ({ id: x[0], Code: x[1], Header: x[2] }));
            },
            default: [],
            watch: ['nomenclatureSearch']
        }
    },
    computed: {
        ...mapGetters('references', ['GetReference']),
        ...mapGetters('actionsource', { dataSource: 'getDataSource', dataSourceType: 'getDataSourceType' }),
        ...mapGetters({ 
            requiredRule: 'getRequiredRule',
            multipleRule: 'getMultipleRule',
            integerRule: 'getIntegerRule',
            integerNotNull: 'getIntegerNotNullRule'
        }),
        documentLanguages: {
            get: function() {
                return this.document.Card.Languages?.data_list ?? [];
            },
            set: function(value) {
                if (this.document.Card.Languages != null)
                    this.updateSource({ property: 'Data.Object.Document.Card.Languages.data_list', value });
                else
                    this.updateSource({ property: 'Data.Object.Document.Card.Languages', value: { data_list: value } });
            }
        },
        isViewMode() {
            return this.dataSource?.Data.FormId == "0201021";
        },
        document() {
            return this.dataSource?.Data.Object.Document;
        },
        pages() {
            let pages = 
            [
                { Count: -1, FormId: this.document?.id, Name: i18n.t("Карточка") }
            ];

            if ((!this.isViewMode || this.document?.ProcessInfo != null) &&
                this.document?.RegState !== 1 &&
                !this.isBypassing)
            {
                pages.push({ Count: -1, FormId: "RouteParams", Name: i18n.t("Маршрут") });
            }
            
            pages = pages.concat(this.dataSource?.Data?.Object?.Pages ?? []);

            return pages;
        },
        isRegistered() {
            return this.document.RegState == 1 || this.document.RegState == 4; 
        },
        concatedRegistrationData() {
            let result = i18n.t("Не_указано");

            if (this.document.Card.RegNumber?.length)
                result = `№ ${this.document.Card.RegNumber}`;

            if (this.document.Card.RegDate)
                result += ` ${this.$t("от_время")} ${this.formatDate(this.document.Card.RegDate)} г.`;

            return result;
        },
        isBypassing() {
            return this.document?.NotDraft === true;
        },
        selectExecuterTooltip(){
            return i18n.t("Выбрать_исполнителя");
        },
        selectRecipientsTooltip(){
            return i18n.t("Выбрать_получателей");
        },
        selectNomenclatureTooltip(){
            return i18n.t("Выбрать_номенклатуру");
        },
        guidEmpty() {
            return sys.guidEmpty();
        }
    },
    data() {
        return {
            tab: null,
            questCharacterSearch: "",
            documentTypeSearch: "",
            nomenclatureSearch: ""
        }
    },
    methods: 
    {      
        ...mapActions('actionsource', { updateSource: 'updateDataSource' }),
        onTabClicked (formId) {
            if (this.$refs[formId])
                this.$refs[formId][0]?.update();
        },
        async onToolbarClick (event, button) {
            let actions = Reflect.ownKeys(this.$store._actions).filter(i => i.includes('actionsource/')).map(i => {return i.split('/')[1]})
            
            if (actions.includes(button.Action)) {
                if(button.Action == 'Save' || button.Action == 'SaveAndClose'){
                    let validate_result = this.$refs.form[0].validate();
                    if(validate_result && this.document.Card.Recipients.length > 0){
                        await this.$store.dispatch(`actionsource/${button.Action}`, {event, button});        
                    }else{
                        this.$notify.alert(i18n.t("Не_заполнены_обязательные_поля"));
                    }
                }
                else
                {
                    await this.$store.dispatch(`actionsource/${button.Action}`, {event, button});
                }
            }
            else
                this.$notify.alert(`Действие_${button.Action}_не_реализовано.`);
        },
        formatDate (source) {
            return this.$moment(source).format('DD.MM.YYYY');
        },
        onIsInitiativeChange (value) {
            this.updateSource({ property: 'Data.Object.Document.Card.IsInitiative', value});

            if (value)
                this.updateSource({ property: 'Data.Object.Document.Card.IsEuolAnswer', value: false});
        },
        onIsEuolAnswerChange (value) {
            this.updateSource({ property: 'Data.Object.Document.Card.IsEuolAnswer', value});

            if (value)
                this.updateSource({ property: 'Data.Object.Document.Card.IsInitiative', value: false});
        },
        async onExecuterSelect (){
            try {
                let selectMemberParams =  { 
                    title: i18n.t("Выбор_исполнителя"),
                    includeInner: true,
                    multiple: false,
                    selected: [ this.document.Card.ExecuterWorkplaceId ]
                }
                let { workplaceId, employeeId, name, contacts } = await this.$store.dispatch('dialogs/selectMembersNew/open', selectMemberParams);                
                this.updateSource({ property: 'Data.Object.Document.Card.ExecuterName',  value: name });
                this.updateSource({ property: 'Data.Object.Document.Card.ExecuterWorkplaceId',  value: workplaceId });
                this.updateSource({ property: 'Data.Object.Document.Card.ExecuterId',  value: employeeId });
                
                let phone = contacts?.find(contact => { return contact.type === "phone"; }) ?? null;
                this.updateSource({ property: 'Data.Object.Document.Card.Phone', value: phone?.value ?? null });
            }
            catch (ex) {
                console.log(ex);
            }
        },
        async onRecipientsSelect () {
            try {
                let selectMemberParams =  { 
                    title: i18n.t("Выбор_получателей"),
                    multiple: true,
                    includeOuterEnterprises: true,
                    includeJuridical: true,
                    includePersons: true,
                    includeExecutersGroups: true,
                    selected: [ ...this.document.Card.Recipients.map(x => x.Id) ]
                }
                let recipients = await this.$store.dispatch('dialogs/selectMembersNew/open', selectMemberParams);
                this.updateSource({ property: 'Data.Object.Document.Card.Recipients', value: recipients.map(x => ({ ContractorType: x.type, Id: x.enterprise, IsEmployee: false, Name: x.name, IsEsedo: x.esedoMember }))});
            }
            catch (ex) {
                console.log(ex);
            }
        },
        async onNomenclatureSelect (){
            try {
                let selected = await this.$store.dispatch('dialogs/selectNomenclature/open', null);
                this.updateSource({ property: 'Data.Object.Document.Nomenclature', value: { Code: selected[1], Header: selected[2] } });
                this.updateSource({ property: 'Data.Object.Document.NomenclatureId', value: selected[0] });
            }
            catch (ex) {
                console.log(ex.message);
            }
        },
        async onCustomRecipientsSelect () {
            try {
                let createdRecipient = await this.$refs.CreateCustomRecipientDlgRef.open();
                let recipients = Array.from(this.dataSource.Data.Object.Document.Card.Recipients);
                recipients.push(createdRecipient);
                this.updateSource({ property: 'Data.Object.Document.Card.Recipients', value: recipients });
            }
            catch (ex) {
                console.log(ex.message);
            }
        },
        onCustomRecipientDelete(index) {
            let recipients = Array.from(this.dataSource.Data.Object.Document.Card.Recipients);
            recipients.splice(index, 1);
            this.updateSource({ property: 'Data.Object.Document.Card.Recipients', value: recipients });
        },
        onNomenclatureInput(event) {
            this.updateSource({ property: 'Data.Object.Document.Nomenclature', value: { Code: event.Code, Header: event.Header } });
            this.updateSource({ property: 'Data.Object.Document.NomenclatureId', value: event.id });
        },
        update: _.debounce(function (object) {
            this.updateSource(object);
        }, 250)
        
    },
    async created() {        
        (async() => {
            while(typeof this.$refs.form === 'undefined')
                await new Promise(resolve => setTimeout(resolve, 100));

            this.$refs?.form?.[0]?.validate();
        })();
    },
    updated() {
        if (this.dataSource !== null) {
            this.$refs?.form?.[0]?.validate();
        }  
    },
    mounted() {        
        this.$eventBus.$on('set-active-tab', async ({ FormId }) => {
            let page = this.pages.find(x => x.FormId === FormId);

            if (page) {
                let targetIndex = this.pages.indexOf(page);

                if (this.tab != targetIndex)
                    this.tab = targetIndex;
                else
                    this.$eventBus.$emit('update-active-tab');
            }
        });
    },
    beforeDestroy() {
        this.$eventBus.$off('set-active-tab');
    }
}
</script>